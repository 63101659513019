<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'

interface Props {
  id?: string
  placeholder?: string
  type?: InputHTMLAttributes['type']
  label?: string
  disabled?: boolean
  hasError?: boolean
  errorMessage?: string
  debounceWaitTime?: number
  bgColor?: string
  maxLength?: number | string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  placeholder: '',
  type: 'text',
  label: '',
  id: () => Math.random().toString(),
  hasError: false,
  errorMessage: '',
  debounceWaitTime: 0,
  bgColor: '#ffffff',
  maxLength: '',
})

const model = defineModel<string | number>()
const debounced = defineModel<typeof model.value>('debounced')

let timeout: NodeJS.Timeout

watch(model, (newVal) => {
  if (props.debounceWaitTime > 0) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      debounced.value = newVal
    }, props.debounceWaitTime)
  }
})

const inputRef = ref<HTMLInputElement>()
defineExpose({ inputRef })
</script>

<template>
  <div
    class="w-full text-primary"
    :class="{
      'cursor-not-allowed opacity-50': disabled,
    }"
  >
    <label
      v-if="label"
      :for="id"
      class="mb-1.5 block text-sm font-medium leading-5"
    >
      {{ label }}
    </label>
    <div
      :style="{ backgroundColor: bgColor }"
      class="flex h-10 items-center gap-2 rounded-md border border-input ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
    >
      <slot name="leftContent" />
      <input
        :id="id"
        ref="inputRef"
        v-model="model"
        :placeholder="props.placeholder"
        :disabled="disabled"
        :type="type"
        :maxlength="maxLength"
        class="h-full w-full flex-1 bg-transparent px-3 text-sm outline-none placeholder:text-sm placeholder:text-muted-foreground"
        data-clarity-unmask="true"
      />

      <slot name="rightContent" />
    </div>

    <p v-if="hasError && errorMessage" class="mt-1.5 text-sm text-red-600">
      {{ errorMessage }}
    </p>
  </div>
</template>
